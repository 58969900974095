<template>
  <div style="padding: 10px;min-height:100vh" class="bg">
    <div class="header">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">旅行社详情</span>
        </van-col>
      </van-row>
    </div>
    <div class="avatar">
        <van-image
          :src="company.avatar"
          round
          fit="cover"
          width="100"
          height="100"
          style="box-shadow: 0px 5px 10px 5px rgba(0, 0, 0.7, 0.1);z-index:888"
        />
      </div>
    <card class="company-info-card">
      <template #content>
        <van-rate
          v-model="company.agency_rate_star"
          :size="25"
          color="rgb(255, 170, 0)"
          void-icon="star"
          allow-half
          readonly
          void-color="#eee"
          style="margin-top:30px"
        />
        <van-row style=" color: black" class="info-row">
          <span style="font-weight: 600">{{ company.name }}</span>
        </van-row>
        <van-row class="info-row" v-if="company.name_en != '' && company.name_en != null"> {{ company.name_en }} </van-row>
        <van-row class="info-row" v-for="item, idx in fields" :key="idx"> 
          <div v-if="item.custom_slot == null">
            {{item.title}}：{{ company[item.name] == "" || company[item.name] == null || company[item.name] == '0'? "暂无": company[item.name] }} 
          </div>
          <div v-else>
            <div v-if="item.custom_slot == 'deposit'">
              {{item.title}}: {{parseInt(company[item.name])}}万元
            </div>
          </div>
        </van-row>
        <van-row style="margin-top:.3rem">
          质保凭条：<van-uploader v-if="company.deposit_receipt != '' || company.deposit_receipt != null" 
            :deletable="false" readonly v-model="deposit_receipt" :max-count="1"  />
              <span v-else>未完善</span>
        </van-row>
        <van-row style="margin-top:.3rem">
          营业执照：<van-uploader v-if="company.license_img != '' || company.license_img != null" 
            :deletable="false" readonly v-model="license_img" :max-count="1"  />
              <span v-else>未完善</span>
        </van-row>
        <van-row style="margin-top:10px">
          <qrCode
            :text="`${baseVistorFrontEndUrl}guider/companyInfo/${company.id}`"
            :margin="0"
            colorDark="#333"
            colorLight="#fff"
            :logoScale="0.2"
            :size="200"
            style="padding:20px;border-radius:20px;background:#ebebeb"
          ></qrCode>
        </van-row>
      </template>
    </card>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import qrCode from 'vue-qr'
import {baseVistorFrontEndUrl} from "@/utils/constants"
export default {
  components: {
    card,
    qrCode
  },
  data: () => ({
    baseVistorFrontEndUrl: baseVistorFrontEndUrl,
    company: {},
    deposit_receipt: [],
    license_img: [],
    fields: [
      {
        title: "地址",
        name: "address",
      },
      {
        title: "法人",
        name: "leagal_person",
      },
      {
        title: "法人电话",
        name: "telephone",
      },
      {
        title: "联系人",
        name: "contact_person",
      },
      {
        title: "联系电话",
        name: "contact_mobile",
      },
      {
        title: "社会信用代码",
        name: "social_credit_card",
      },
      {
        title: "发证单位",
        name: "unit",
      },
      {
        title: "许可证号",
        name: "license_no",
      },
      {
        title: "许可文号",
        name: "license_file_no",
      },
      {
        title: "经营场所",
        name: "place_of_business",
      },
      {
        title: "传真",
        name: "fax",
      },
      {
        title: "质保金",
        name: "deposit",
        custom_slot: "deposit"
      },
      {
        title: "质保银行",
        name: "telephone",
      },
      
    ]
  }),
  created() {
    const _self = this
    this.$store.dispatch('wode/getAgency', this.$route.params.id).then((res) => {
      _self.company = res.data.agency
      _self.deposit_receipt.push({url: _self.company.deposit_receipt})
      _self.license_img.push({url: _self.company.license_img})
    })
  },
  methods: {
    sign_out() {
      this.$router.push("/login");
    },
    onClickBack() {
      this.$router.go(-1)
    },
  },
};
</script>

<style scoped>
.wode-header {
  height: 110px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}
.share-icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  padding: 5px;
  background: #fff;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  margin-top: 5px;
}
.fenxiang-img {
  width: 80px;
}
.submit button {
  height: 0.85rem;
  line-height: 0.85rem;
  text-align: center;
  font-size: 0.34rem;
  color: #ffffff;
  border-radius: 0.85rem;
  outline: none;
  box-shadow: 0 0.15rem 0.15rem rgba(31, 110, 207, 0.2);
  display: inline-block;
  width: 90%;
  margin: 0 auto;
  background: #1f6ecf;
  margin-left: 5%;
}
.company-info-card {
  margin-top: 2rem;
  font-size: 14px;
  color: grey;
  padding: 1rem 0 4rem 0;
  text-align: center;
  height: 80vh;
  overflow-y: scroll;
}
.info-row {
  margin-top: 15px;
}
.action_btn {
  position: fixed;
  bottom: 10px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}
.avatar {
  position:absolute;
  padding-right: .2rem;
  top: 2rem;
  text-align: center;
  width: 100%;
  display: block;
  z-index: 200;
}
</style>